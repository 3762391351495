<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.edit', {name: $tc('message.reminder', 2)}) }}</h3>
    <calendarBreadcrumbs :link="'dashboardReminder'" />
    <transition name="flixFadeIn">
      <div v-if="updateKey && data" :key="updateKey">

        <div class="flix-form-group">
          <div class="flix-pull-right">
            <a href="#" class="flix-html-a flix-text-primary" @click.prevent="function(){ presets = !presets }">
              <flixIcon v-if="!presets" :id="'chevron-up'"/>
              <flixIcon v-else :id="'chevron-down'"/>
              {{ $t('message.add', {name: $tc('message.presets', 2)}) }}
            </a>
          </div>
          <router-link :to="{name: 'dashboardDefaultMessages', params: {id: $route.params.id}}" class="flix-html-a"><flixIcon id="message-out" /> {{ $t('message.edit', {name: $tc('message.appointmentNotification', 2)}) }}</router-link>
        </div>
        <transition name="flixFadeIn">
          <div class="flix-form-group" :key="presets + updateKey">
            <presets v-if="presets" :callback="function(ret) { data = ret; updateKey = new Date().getTime(); presets = false }"/>
            <show v-else :rawData="data" />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    show () { return import('@/components/reminder/show') },
    presets () { return import('@/components/reminder/presets') }
  },
  props: {},
  data () {
    return {
      updateKey: false,
      data: false,
      presets: false
    }
  },
  watch: {
    '$route.params.id' () { this.getAssistent() }
  },
  methods: {
    getAssistent () {
      if (this.$route.params.id === 'all') {
        this.data = false
        this.updateKey = new Date().getTime()
        this.presets = false
        return false
      }
      this.$store.dispatch('getReminder', {
        ID: this.$route.params.id,
        callback: function (data) { this.data = data; this.updateKey = new Date().getTime(); this.presets = false }.bind(this)
      })
    }
  },
  mounted () {
    this.getAssistent()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
